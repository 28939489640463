@import "/src/assets/scss/_variables.scss";

.main {
  background-color: $color5;
  border-top: 10px solid $color3;
  align-items: center;
}

.panel-texto-bienvenida {
  padding: 0 1.5em;  

  @media (min-width: $bp-xl) {
    width: 60%;
    padding: 1.5em;    
  }
}

.panel-foto-seguridad-vial {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.5em;  
  overflow: hidden;

  img {
    border-left: solid 12px $color4;
    border-radius: 5px;
  }

  @media (min-width: $bp-xl) {
    width: 40%;
  }
}

