@import "../assets/scss/variables";

.panel-redes {
  text-align: center;
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2em;
    list-style: none;

    li {
      font-size: xx-large;
      color: $color1;
      border-bottom: 3px solid transparent;

      &:hover {
        color: $color5;
        border-bottom: 3px solid $color5;
        transition: all 0.5s;
      }

      a,
      a:visited {
        color: $color1;
        border-bottom: 3px solid transparent;
      }
    }
  }
}
