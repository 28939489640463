@import "../assets/scss/variables";

footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: $color4;
  border-top: 10px solid $color3;
  padding: 2em;
}

.isotipo {
  width: 100px;
}

.panel-logo-redes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.panel-copyright {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  color: $color1;
  font-family: $fuente-texto;
}

.mapa-navegacion {
  color: $color1;
  font-family: $fuente-texto;

  h3 {
    font-family: $fuente-titulo;
    margin-bottom: 1em;
  }

  ul {
    text-align: left;
  }
}

.panel-contacto {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $color1;
  font-family: $fuente-texto;

  h3 {
    font-family: $fuente-titulo;
    margin-bottom: 1em;
  }

  ul {
    text-align: left;
    list-style: none;
  }
}

.banners {
  display: flex;
  flex-direction: column;
  justify-content: center;  
  align-items: center;
  gap: 0.5rem;

  img {    
    max-width: 350px;
    background-color: $color5;
    padding: 1.5rem;
    border-radius: 15px;
  }
}
