// Colores:
$color1: #a7c4d2;
$color2: #6b7d87;
$color3: #373435;
$color4: #294452;
$color5: rgb(240, 240, 240);

// Fuentes:
$fuente-titulo: "Oswald", sans-serif;
$fuente-texto: 'Roboto Slab', serif;

// Breakpoints:
$bp-xl: 1200px;