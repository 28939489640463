@import "/node_modules/bootstrap/scss/bootstrap.scss";
@import "/node_modules/bootstrap-icons/font/bootstrap-icons.scss";
@import "/src/assets/scss/variables";

p {
  font-family: $fuente-texto;
  font-weight: 300;
  font-size: 18px;  

  @media (min-width: $bp-xl) {
    font-size: 24px;
  }
}

a, a:visited {
  text-decoration: none;
  color: #000;
  font-weight: 400;
  border-bottom: dashed 1px #000;
}

h2 {
  font-family: $fuente-titulo;
  font-size: 50px;
}

h3 {
  font-family: $fuente-titulo;
}

.imagen-zoom:hover {
  transform: scale(1.02);
  transition: transform 0.3s;
}

.separador {
  border-bottom: solid 1px $color2;
  width: 100%;
  margin: 1.5em 0;

  @media (min-width: $bp-xl) {
    width: 0;
    margin: 0;
    border-bottom: solid 1px transparent;
    border-right: solid 1px $color2;
  }
}

.italic {
  font-style: italic;
}

.fotoHeader {
  width: 100%;
  border-bottom: 10px solid $color3;
}

strong {
  font-weight: 600;
}

.form-control, .form-text {
  font-family: $fuente-texto;
}

.form-label {
  font-family: $fuente-texto;
  font-size: large;
}