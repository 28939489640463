@import "/src/assets/scss/variables";

nav {
  background-color: $color4;
  padding: 1em 0 !important;
}

.logo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  height: 5em;

  img {
    height: 100%;
  }
}

.titulo-logo {
  color: $color1;
  font-family: $fuente-titulo;
  font-weight: 500;
  font-size: x-large;
}

.nav-link {
  font-family: $fuente-titulo;
  font-weight: 200;
  font-size: x-large;
  border-bottom: transparent;
}

.dropdown-item {
  font-family: $fuente-texto;
  font-weight: 300;
}
