@import "../assets/scss/_variables.scss";

#carrusel-home {
  height: 100%;

  @media (min-width: $bp-xl) {
    height: 52em;
  }
}

.carousel-inner {
  height: 100%;
}

.carrusel-imagen {
  width: 100%;
}
