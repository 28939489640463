.formulario-contacto {
    flex-grow: 2;
}

.mapa {
    
}

form {
    input {
        max-width: 400px;
    }
    button {
        width: 200px;
    }
}